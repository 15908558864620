import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { CommonVN, ManageShopVN, ProductVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormSelect } from './form-select/FormSelect'
import { FormInput } from './form-input/FormInput'
import { MAX_LENGTH_SEARCH } from 'constants/constants'
import { includes } from 'lodash'
import { getStorage } from 'helpers/global_helper'
import { FormDateRange } from './form-date-range/FormDateRange'
import dayjs from 'dayjs'

const ShopListGlobalFilter = ({ setGlobalFilter, search, setSearch, isKTV }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, ward, myLocationManagement } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  useEffect(() => {
    if (!isKTV) {
      const filterMangeShop = getStorage('filterManageShop')
      if (filterMangeShop?.start_date) filterMangeShop.start_date = dayjs(filterMangeShop.start_date)
      if (filterMangeShop?.end_date) filterMangeShop.end_date = dayjs(filterMangeShop.end_date)
      if (filterMangeShop?.start_date1) filterMangeShop.start_date1 = dayjs(filterMangeShop.start_date1)
      if (filterMangeShop?.end_date1) filterMangeShop.end_date1 = dayjs(filterMangeShop.end_date1)
      setFilter(filterMangeShop)
    } else {
      const filterMangeKTV = getStorage('filterManageKTV')
      if (filterMangeKTV?.start_date) filterMangeKTV.start_date = dayjs(filterMangeKTV.start_date)
      if (filterMangeKTV?.end_date) filterMangeKTV.end_date = dayjs(filterMangeKTV.end_date)
      if (filterMangeKTV?.start_date1) filterMangeKTV.start_date1 = dayjs(filterMangeKTV.start_date1)
      if (filterMangeKTV?.end_date1) filterMangeKTV.end_date1 = dayjs(filterMangeKTV.end_date1)
      setFilter(filterMangeKTV)
    }
  }, [])

  const lstDistrict = myLocationManagement
  console.log(lstDistrict);

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)
  console.log(wardParentIds);

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  const subZoneMemo = useMemo(() => {
    const parentDatas = filter?.zone_ids?.length > 0 ? filter.zone_ids : lstZone.map(e => e.id);
    return lstSubZone?.filter((e) => parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true)?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
        lstSubZone
          ?.filter((e) => parentDatas?.length > 0 ? parentDatas?.includes(e.zoneId) : true)
          ?.map((item) => ({
            value: item.id,
            label: item.name
          }))
      )
      : []
  }, [lstSubZone, lstZone, filter]);

  const provinceMemo = useMemo(() => {
    const parentDatas = filter?.sub_zone_ids?.length > 0 ? filter.sub_zone_ids : subZoneMemo.map(e => e.value);
    console.log('provinces', parentDatas)
    return lstProvionce?.filter((e) => parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true)?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
        lstProvionce
          ?.filter((e) => parentDatas?.length > 0 ? parentDatas?.includes(e.subZoneId) : true)
          ?.map((item) => ({
            value: item.id,
            label: item.name
          }))
      )
      : []
  }, [lstProvionce, filter])

  const districtMemo = useMemo(() => {
    const parentDatas = filter?.province_ids?.length > 0 ? filter.province_ids : provinceMemo.map(e => e.value);
    return lstDistrict?.filter((e) => parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true)?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
        lstDistrict
          ?.filter((e) => parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true)
          ?.map((item) => ({
            value: item.id,
            label: item.name
          }))
      )
      : [];
  }
    , [lstDistrict, filter]);

  const wardMemo = useMemo(() => {
    const parentDatas = filter?.district_ids?.length > 0 ? filter.district_ids : districtMemo.map(e => e.value);
    return lstWard?.filter((e) => parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true)?.length > 0
      ? [{ label: 'Tất cả', value: 'all' }].concat(
        lstWard
          ?.filter((e) => parentDatas?.length > 0 ? parentDatas?.includes(e.parentId) : true)
          ?.map((item) => ({
            value: item.id,
            label: item.name
          }))
      )
      : []
  }, [lstWard, filter])
  return (
    <React.Fragment>
      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>Nhập tên</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên'
          value={filter?.search || ''}
          name='search'
          id={`input-filter`}
          onChange={(value) => {
            handleSelect(value.target.value, 'search')
          }}
          onBlur={(value) => {
            handleSelect(value.target.value, 'search')
          }}
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>Nhập số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập SĐT '
          value={filter?.phone || ''}
          name='phone'
          id={`input-filter`}
          onChange={(value) => {
            handleSelect(value.target.value, 'phone')
          }}
          onBlur={(value) => {
            handleSelect(value.target.value, 'phone')
          }}
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter?.zone_ids}
          onChange={(value, label) => {
            handleSelect(label.map((e) => e.label, 'zone_names'))
            handleSelect(value, 'zone_ids')

            handleSelect([], 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
            handleSelect([], 'sub_zone_names')
            handleSelect([], 'province_names')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            const all_names = lstZone?.map((item) => item.id)

            if (value && value === 'all') {
              handleSelect([...all], 'zone_ids')
              handleSelect([...all_names], 'zone_names')
            }
          }}
          placeholder={ProductVN.filter?.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Vùng</Label>
        <FormSelect
          options={subZoneMemo}
          value={filter?.sub_zone_ids}
          onChange={(value, label) => {
            handleSelect(value, 'sub_zone_ids')
            handleSelect(label.map((e) => e.label, 'sub_zone_names'))

            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
            handleSelect([], 'province_names')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const [_, ...allItems] = subZoneMemo;
            const all = allItems.map((item) => item.value)
            const all_names = allItems.map((item) => item.label);
            if (value && value === 'all') {
              handleSelect([...all], 'sub_zone_ids')
              handleSelect([...all_names], 'sub_zone_names')
            }
          }}
          placeholder={ProductVN.filter?.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Tỉnh/ Thành phố</Label>
        <FormSelect
          options={provinceMemo}
          value={filter?.province_ids}
          onChange={(value, label) => {
            handleSelect(value, 'province_ids')
            handleSelect(label.map((e) => e.label, 'province_names'))

            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const [_, ...allItems] = provinceMemo;
            const all = allItems.map((item) => item.value)
            const all_names = allItems.map((item) => item.label);

            if (value && value === 'all') {
              handleSelect([...all], 'province_ids')
              handleSelect([...all_names], 'province_names')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{'Quận/ Huyện'}</Label>
        <FormSelect
          options={districtMemo}
          value={filter?.district_ids}
          onChange={(value, label) => {
            handleSelect(value, 'district_ids')
            handleSelect(
              label.map((e) => e.label),
              'district_names'
            )
            handleSelect([], 'ward_ids')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const [_, ...allItems] = districtMemo;
            const all = Array.from(new Set(allItems.map((item) => item.value)))
            const all_names = allItems.map((item) => item.label);

            if (value && value === 'all') {
              handleSelect([...all], 'district_ids')
              handleSelect([...all_names], 'district_names')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{'Phường xã'}</Label>
        <FormSelect
          options={wardMemo}
          value={filter?.ward_ids}
          onChange={(value, label) => {
            handleSelect(value, 'ward_ids')
            handleSelect(
              label.map((e) => e.label),
              'ward_names'
            )
          }}
          onSelect={(value) => {
            const [_, ...allItems] = Array.from(new Set(wardMemo));
            const all = allItems.map((item) => item.value)
            const all_names = allItems.map((item) => item.label);

            if (value && value === 'all') {
              handleSelect([...all], 'ward_ids')
              handleSelect([...all_names], 'ward_names')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{ManageShopVN.filters.status.title}</Label>
        <FormSelect
          options={ManageShopVN.statusOptions?.map((item) => ({
            value: item.value,
            label: item.label
          }))}
          value={filter?.status}
          onChange={(value, label) => {
            handleSelect(value, 'status')
            handleSelect(label?.label, 'status_name')
          }}
          placeholder='Tất cả'
        />
      </Col>
      {!isKTV && (
        <Col xxl={6} lg={6} xs={6} className='mt-5'>
          <>
            <Label className='form-label'>{ManageShopVN.filters.statusAccount.title}</Label>
            <FormSelect
              options={ManageShopVN.statusAccountOptions?.map((item) => ({
                value: item.value,
                label: item.label
              }))}
              value={filter?.member}
              onChange={(value, label) => {
                handleSelect(value, 'member')
                handleSelect(label.label, 'member_name')
              }}
              placeholder='Tất cả'
            />
          </>
        </Col>
      )}

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>Ngày tạo TK</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter?.start_date1, filter?.end_date1]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date1')
              handleSelect(value[1], 'end_date1')
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date1')
              handleSelect(undefined, 'end_date1')
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>

      <Col xxl={4} lg={6} xs={6} className='d-flex align-items-end justify-content mt-5'>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                search: '',
                phone: '',
                zone_ids: undefined,
                zone_names: undefined,
                sub_zone_ids: undefined,
                sub_zone_names: undefined,
                province_ids: undefined,
                province_names: undefined,
                district_ids: undefined,
                district_names: undefined,
                ward_ids: undefined,
                ward_names: undefined,

                status: undefined,
                status_name: undefined,

                member: undefined,
                member_name: undefined,
                start_date1: undefined,
                end_date1: undefined,
                start_date: undefined,
                end_date: undefined
              })
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              console.log('filter :>> ', filter)
              setGlobalFilter({ ..._filter })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ShopListGlobalFilter
