import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
  Col, Row, UncontrolledTooltip, Card, CardBody,
} from 'reactstrap';
import Spinners from 'components/Common/Spinner';
import { toast } from 'react-toastify';
import { AccumulationVN } from 'constants/vn';
import ConfirmModal from 'components/Common/ConfirmModal';
import {
  deleteAccumulationAPI,
  exportAccumulationFetcherAPI,
  getActivationListReportAPI
} from 'helpers/backend_helper';
import { ACCUMULATED_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code';
import PermissionWrapper from 'components/Common/PermissionWrapper';
import { Space } from 'antd';
import { TableRowSpan } from 'components/Common/TableCustom';
import { setFilter } from 'store/actions';
import ManageAccumulationFilter from './components/ActivationReportFilter';
import AddEditAccumulation from '../ManageAccumulation/components/AddEditAccumulation';
import {
  AccumulationModel,
  Serial,
  Zone,
  StatusAccumulation,
  Shop,
  ActivatedDate,
  LongLatToDMS,
} from '../ManageAccumulation/ManageAccumulationCol';
import { FormInput } from 'components/Common/form-input/FormInput';

function DetailActivationReport() {
  // meta title
  document.title = 'Accumulation List | HPG';

  const screenCode = useMemo(() => SCREEN_CODE?.ACCUMULATED_MANAGEMENT, []);

  const selectAccumulationsState = (state) => state.AccumulationReducer;
  const AccumulationsProperties = createSelector(selectAccumulationsState, (reducer) => ({
    accumulations: reducer.accumulations,
    loading: reducer.loading,
    total: reducer.total,
  }));

  const selectProfileState = (state) => state.Profile;
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile,
  }));
  const { profile } = useSelector(ProfileProperties);

  const [accumulations, setAccumulations] = useState([])

  const selectLayoutState = (state) => state.Layout;
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter,
  }));
  const { filter } = useSelector(LayoutProperties);

  const [isLoading, setLoading] = useState(true);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [selected, setSelected] = useState();
  const [showForm, setShowForm] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalItems: 0,
  });


  const onClickDelete = (item) => {
    setSelected(item);
    setShowDeleteAccount(true);
  };

  const onClickItem = async (item, type) => {
    setSelected(item);
    setShowForm(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: AccumulationVN.table.shop,
        accessor: 'usersShopName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Shop {...cellProps} />,
      },
      {
        Header: 'Ngành',
        accessor: 'industryName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Zone {...cellProps} />,
      },
      {
        Header: 'Ngành hàng',
        accessor: 'industrySectorName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Zone {...cellProps} />,
      },
      {
        Header: 'Nhóm sản phẩm',
        accessor: 'categoryName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Zone {...cellProps} />,
      },
      {
        Header: AccumulationVN.table.model,
        accessor: 'modelName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <AccumulationModel {...cellProps} />,
      },
      {
        Header: AccumulationVN.table.serial,
        accessor: 'serial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Serial {...cellProps} />,
      },
      {
        Header: AccumulationVN.table.activatedDate,
        accessor: 'userRewardCreatedAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <ActivatedDate {...cellProps} />,
      },
      {
        Header: 'Miền xuất kho',
        accessor: 'productZoneName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <span>{cellProps.value}</span>,
      },
      {
        Header: 'Kênh bán hàng',
        accessor: 'channelName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <span>{cellProps.value}</span>
      },
      {
        Header: AccumulationVN.table.status,
        accessor: 'status',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <StatusAccumulation {...cellProps} />,
      },
      {
        Header: 'Tổng tiền',
        accessor: 'reward',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => <Zone {...cellProps} />,
      },
      {
        Header: 'Lý do từ chối',
        accessor: (cell) => {
          return `${cell.id},${cell.productId},${cell.uuid},${cell.checked}`
        },
        Cell: (cellProps) => {
          return (
            <FormInput
              maxLength={50}
              // value={valueFilter || ''}
              // value={descriptionState[cellProps.row.id] || ''}
              // disabled={!JSON.parse(cellProps?.value?.split(',')[3])}
              // onChange={(e) => {
              //   handleUpdateDescription(cellProps.value, e.target.value)
              //   HandleSetDescription(cellProps.row.id, e.target.value.trim())
              // }}
              // onBlur={(e) => {
              //   HandleSetDescription(cellProps.row.id, e.target.value.trim())
              // }}
            />
          )
        }
      },
      {
        Header: AccumulationVN.table.action,
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <Link
                to="#"
                className="btn btn-sm btn-soft-primary"
                id={`viewtooltip-${cellProps.row.original.id}`}
                onClick={() => {
                  onClickItem(cellProps.row.original, 'view');
                }}
              >
                <i className="mdi mdi-eye-outline" />
                <UncontrolledTooltip
                  trigger="hover"
                  placement="top"
                  target={`viewtooltip-${cellProps.row.original.id}`}
                >
                  Xem
                </UncontrolledTooltip>
              </Link>
            </li>

            <PermissionWrapper
              screenCode={screenCode}
              code={ACCUMULATED_MANAGEMENT_CODE.ADMIN_DELETE_USER_REWARD_ACCUMULATED}
            >
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    onClickDelete(cellProps.row.original);
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    trigger="hover"
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Xóa
                  </UncontrolledTooltip>
                </Link>
              </li>
            </PermissionWrapper>
          </ul>
        ),
      },
    ],
    [],
  );

  const onInit = async (payload) => {
    payload =  payload ?  { ...payload, programming_id: 5 } : { programming_id: 5 };
    const x = await getActivationListReportAPI(payload);
    setAccumulations(x.data);
    console.log(x)
    setPagination(x.meta);
  }
  useEffect(() => { onInit() },[])

  const setGlobalFilterUser = async (data) => {
    if (data.start_date1 !== undefined && data.end_date1 !== undefined) {
      data.start_date = data.start_date1.startOf('day').toDate();
      data.end_date = data.end_date1.endOf('day').toDate();
    } else {
      data.start_date = undefined;
      data.end_date = undefined;
    }
    onInit(data);
  };

  const goToPage = (page) => {
    onInit({ page: page });
    setPage(page);
  };

  const onDeleteAccount = async () => {
    try {
      console.log('selected', selected);
      const res = await deleteAccumulationAPI({
        id: selected.userRewardId,
      });
      if (res) {
        toast('Xóa thông tin tích lũy thành công', { type: 'success' });
        setShowDeleteAccount(false);
        setPage(page);
      }
    } catch (error) {
      console.log(error);
      toast(error.response.data.message, { type: 'error' });
    }
  };

  const handleClickExport = async () => {
    // setLoading(true)
    // try {
    //   const res = await exportAccumulationAPI(filter)
    //   if (res?.data?.success === false) {
    //     toast(res?.message, { type: 'error' })
    //   } else {
    //     toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
    //       type: 'success'
    //     })
    //   }
    // } catch (error) {
    //   toast(error.response.data.message, { type: 'error' })
    // }
    // setLoading(false)

    setLoading(true);
    try {
      const response = await exportAccumulationFetcherAPI({ ...filter });
      const data = response?.headers?.get('Content-Type');
      if (data !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        toast(`Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ${profile?.email} trong vài phút`, {
          type: 'success',
        });
      } else {
        try {
          const blob = await response.blob(); // Fetch the blob
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${moment().format('yyMMDD')}_Danh sach tich luy.xlsx`); // Tên file
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          toast('Export file thành công!', { type: 'success' });
        } catch (err) {
          console.log('err', err);
        }
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleResetFilter = () => {
  };

  return (
    <>
      <div className="page-content">
        <div className="page-title-box container-fluid">
          <h4 className=" mb-sm-0 font-size-18 pb-4">Báo cáo chi tiết tích lũy</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0 card-title flex-grow-1">{AccumulationVN.list}</h5>
                      <div className="flex-shrink-0">
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={ACCUMULATED_MANAGEMENT_CODE.ADMIN_EXPORT_ACCUMULATED}
                        >
                          <button onClick={() => handleClickExport()} className="btn btn-primary me-2 pe-4">
                            <i className="mdi mdi-database-export me-1 ms-2" />
                            {AccumulationVN.export}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Space size="middle" direction="vertical" className="w-100">
                      <ManageAccumulationFilter
                        handleFilter={setGlobalFilterUser}
                        handleResetFilter={handleResetFilter}
                      />
                      <h4 className=" mb-sm-0 font-size-18 pb-4">Báo cáo chi tiết tích lũy 
                          <button className='btn btn-warning btn-rounded me-2'>Chờ đối soát: 100</button>
                          <button className='btn btn-info btn-rounded me-2'>Đã đối soát: 8</button>
                          <button className='btn btn-danger btn-rounded me-2'>Đã từ chối: 1</button>
                      </h4>
                      <TableRowSpan
                        columns={columns}
                        data={accumulations}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={pagination.totalItems}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDeleteAccount}
        icon={(
          <div className="avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3">
            <i className="mdi mdi-delete-alert" />
          </div>
        )}
        onConfirmClick={onDeleteAccount}
        onCloseClick={() => setShowDeleteAccount(false)}
        title="Xóa bản ghi"
        description={'Các kết quả thưởng của bản ghi sẽ bị hủy.\n Bạn có chắc chắn muốn xoá bản ghi tích lũy?'}
      />
      <AddEditAccumulation
        onCloseClick={() => {
          setShowForm(false);
        }}
        show={showForm}
        page={page}
      />
    </>
  );
}

export default DetailActivationReport;
